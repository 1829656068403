@import "src/assets/styles/variables";

.TermsAndConditionsTitle {
  padding-top: 50px;
  padding-bottom: 100px;

  @extend .white;
  @extend .semibold;
  @extend %xxl;
}

.TermsAndConditionsContainer {
  margin: 0 auto 50px;
  max-width: 1024px;
  padding: 0 50px ;
}

.TermsAndConditionsContainer p {
  margin-bottom: 20px;
  text-align: justify;
}

.TermsAndConditionsContainer .titlePoint {
  border-left: solid 7px #004ad1;
  color: #363636;
  font-size: 16px;
  padding: 10px;
  line-height: 16px;
  margin-bottom: 20px;
  font-weight: bold;
}

.TermsAndConditionsContainer .subTitlePoint {
  color: #363636;
  font-size: 16px;
  padding: 10px;
  line-height: 16px;
  margin-bottom: 20px;
  font-weight: bold;
}