@import "src/assets/styles/variables.scss";

.subheaderWrapper {
  background-color: rgba(254, 254, 254, 0.1);
  box-sizing: border-box;
  font-family: $font;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.rightLinks {
  @extend .right;
}

.rightLinksSmall {
  @extend .right;
  @media #{$on-sm} {
    display: none;
  }
}

.subheader {
  @extend .container;
  padding: 0;
  box-sizing: inherit;
}

.subheaderList {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    @extend %small;
    @extend .white;
    display: inline;
    margin: 0 4px 0 4px;
    a {
      @extend %small;
      @extend .white;
      display: inline-block;
      height: 33px;
      line-height: 33px;
      padding: 0 10px 0 10px;
      text-decoration: none;

      img {
        width: 16px;
        height: 16px;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        @media #{$before-md} {
          height: 12px;
        }
      }
    }
  }
}

.first {
  padding-left: 0 !important;
  padding-right: 10px !important;
  @media #{$before-sm} {
    padding-left: 10px !important;
  }
  img {
    margin-right: 5px;
  }
}

.last {
  padding-right: 2px !important;
}

.borderRight {
  border-right: solid 1px $white;
  @media #{$before-sm} {
    border-right: 0;
  }
}

.borderRightInverse {
  border-right: none !important;
  @media #{$before-sm} {
    border-right: solid 1px $white !important;
  }
}

.inverseLink {
  color: $blue !important;
  @media #{$before-sm} {
    color: $white !important;
  }
}
