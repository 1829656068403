@import "../../styles/variables.scss";
// $fa-font-path: "font-awesome/fonts";
// @import "~font-awesome/scss/font-awesome";

$blue-hero: #095dfa;
$hero-height: 882px;

.heroWrapper {
  width: 100%;
  position: relative;
}

.hero {
  @extend %container;
  max-height: $hero-height;
  position: relative;
  background-color: $white;
  @media #{$before-sm} {
    background-color: $blue;
    background: url("../../img/bg-footer.png") repeat-y;
    margin-bottom: 80px;
  }

  @media #{$before-xs} {
    background-color: $blue;
    background: url("../../img/bg-footer.png") repeat-y;
    margin-bottom: 120px;
  }
}

.heroBackground {
  z-index: 0;
  position: absolute;
  right: 42%;
  top: 0;
  height: 100%;

  @media #{$before-lg} {
    right: 42%;
  }
  @media #{$before-md} {
    right: 30%;
  }
  @media #{$before-sm} {
    display: none;
    right: -20%;
  }
  
}

.heroCompleteBackground {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-color: $blue-hero;
  @media #{$before-sm} {
    display: none;
    right: 30%;
  }
}

.heroContent {
  position: relative;
  height: 100%;
}

.heroContentLeft {
  @extend .left;
  width: 50%;
  @media #{$before-sm} {
    width: 100%;
    text-align: center;
  }
  & > div {
    margin-top: 402px;
    padding-left: 30px;

    @media #{$before-md} {
      margin-top: 322px;
    }

    @media #{$before-sm} {
      margin-top: 120px;
      padding-left: 0px;
    }

  }
}

.heroContentRight {
  @extend .left;
  width: 50%;
  @media #{$before-sm} {
    width: 100%;
  }
}

.heroContentImage {
  height: 523px;
  margin-top: 224px;
  padding-top: 20px;
  position: relative;
  overflow-x: visible;

  @media #{$before-md} {
    margin-top: 179px;
    height: 320px;
  }

  @media #{$before-sm} {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  img {
    height: 100%;
    position: absolute;
    left: 5%;
    @media #{$before-sm} {
      height: 103%;
      left: 30%;
    }

    @media #{$before-xs} {
      left: 45px;
    }
  }
}

.modalClose {
  color: $white;
  position: absolute;
  z-index: 99999;
  right: 0;
  top: -20px;
}