@import "src/assets/styles/variables";
// $fa-font-path: "font-awesome/fonts";
// @import "~font-awesome/scss/font-awesome";

.payPlansWrapper {
  background: -webkit-linear-gradient(to bottom right, #004ad1, #003ba9);
  background: linear-gradient(to bottom right, #004ad1, #003ba9);
}

.payPlans {
  @extend %container;
  padding: 98px 0 98px 0;
  > div {
    color: #ffffff;
    max-width: 580px;
    hr {
      border-color: #ffffff;
    }
  }

  @media #{$before-sm} {
    padding-bottom: 0px;
  }
}
.section {
  width: 50%;
  float: left;
  padding-left: 89px;
  box-sizing: border-box;
  @media #{$before-md} {
    width: 100%;
    text-align: center;
  }

  @media #{$before-sm} {
    padding-left: 20px;
  }

  p {
    @extend .light;
    @extend %normal-title;

    color: #ffffff;
    max-width: 477px;
    padding-top: 28px;
  }
}

.payPlansButtons {
  padding-top: 27px;
}

.payPlansCarousel {
  position: relative;
  height: 450px;

  @media #{$before-md} {
    padding-top: 20px;
  }
}

.roundButton {
  @extend .light;
  @extend %l;
  cursor: pointer;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  border: solid 1px rgba(254, 254, 254, 0.9);
  color: rgba(254, 254, 254, 0.9);
  text-align: center;
  line-height: 39px;
  float: left;
  margin-left: 11px;
  text-decoration: none;
}

.item1 {
  width: 399px !important;
  height: 450px !important;
  border-radius: 18.55px;
  position: absolute;
  top: 0;
  z-index: 999999;
}
.item2 {
  width: 399px !important;
  height: 400px !important;
  border-radius: 18.55px;
  position: absolute;
  top: 25px;
  left: 35px;
  z-index: 99999;
}
.item3 {
  width: 399px !important;
  height: 350px !important;
  border-radius: 18.55px;
  position: absolute;
  top: 50px;
  left: 70px;
}

.itemFooter {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 18.55px;
  border-bottom-right-radius: 18.55px;
  > p {
    @extend %normal;
    text-align: center;
    color: $dark;
    padding: 20px 10px;
  }
}

.red {
  background: -webkit-linear-gradient(to bottom right, #f55b55, #f78c87);
  background: linear-gradient(to bottom right, #f55b55, #f78c87);
}
.orange {
  background: -webkit-linear-gradient(to bottom right, #fad961, #f76b1c);
  background: linear-gradient(to bottom right, #fad961, #f76b1c);
}
.green {
  background: -webkit-linear-gradient(to bottom right, #5ae173, #19853c);
  background: linear-gradient(to bottom right, #5ae173, #19853c);
}
