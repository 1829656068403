@import "../variables.scss";
@import "../style.scss";

.footerWrapper {
  width: 100%;
  min-height: 450px;
  background: url('../../img/bg-footer.png') repeat-y;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  position: relative;
}

.footer {
  @extend %container;
  @extend .regular;
  @extend .white;
  padding-bottom: 100px;
}

.footerSection {
  float: left;
  width: 50%;
  padding: 45px 0 0 45px;
  position: relative;
  box-sizing: border-box;
  @media #{$before-md} {
    width: 100%;
    padding: 45px 0 0 12px;
  }

  @media #{$before-sm} {
    padding: 45px 0 0 0;
  }

  h3 {
    @extend .bold;
    font-size: 22px;
    padding: 16px 0 10px 0;
  }
  ul {
    margin: 6px 0 0 0;
    padding: 0;
    list-style-type: none;
    li {
      width: auto;
      margin-right: 27px;
      display: inline;
    }
  }

  form {
    max-width: 408px;
    position: relative;

    label {
      margin: 10px 0;
      font-size: 14px;
    }

    button {
      cursor: pointer;
      float: right;
      margin-top: 14px;
      margin-right: -20px;
      border-radius: 100px;
      color: #004ad1;
      border: none;
      padding: 15px 56px;
      font-size: 18px;
      @extend .regular;
      background-color: $white;
    }
  }
}

.checkbox {
  margin: 0 0 0 3px;
  line-height: normal;
  box-sizing: border-box;
  -ms-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -o-transform: scale(1.4);
  padding: 10px;
}

.inputFooter {
  @extend .light;
  @extend .white;
  margin: 10px 0;
  padding: 5px;
  background: transparent;
  border: none;
  border-bottom: solid 3px $white;
  width: 100%;
  font-size: 18px;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: $white;
  }
  &::-moz-placeholder {
    color: $white;
  }
  &:-ms-input-placeholder {
    color: $white;
  }
  &:-moz-placeholder {
    color: $white;
  }
}

.logo {
  height: 40px !important;
}

.know {
  margin-top: 36px !important;
}

.followUs {
  margin-top: 36px !important;
}

.subfooterWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #021d99;
  min-height: 33px;
  box-sizing: border-box;
}

.subfooter {
  @extend %container;

  @extend .light;
  @extend .white;
  @extend %normal;
  box-sizing: border-box;

  ul {
    margin: 6px 0 0 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-right: 27px;
      display: inline;
      a {
        @extend .light;
        @extend .white;
        text-decoration: none;
      }
    }
  }
}

.formWrapper {
  width: 0 auto;
  float: right;
  padding-right: 100px;
  @media #{$before-md} {
    float: none;
    padding-right: 0;
  }
}

.pakke{
  a {
    position: relative;
    img {
      position: absolute;
    }
  }
}


