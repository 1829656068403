@import "src/assets/styles/variables";

.acceptWrapper {
  background-color: #f6f7f9;
  min-height: 518px;
  margin-top: 50px;
  margin-bottom: 70px;
}

.accept {
  @extend %container;
}

.section {
  width: 50%;
  position: relative;

  @media #{$before-md} {
    width: 100%;
    text-align: center;
  }

  img {
    position: absolute;
    right: -49px;
    top: 59px;
    max-height: 529px;
    @media #{$before-md} {
      top: 59px * .7;
      right: 0;
      margin: 0 auto;
      max-width: 100%;
      position: relative;
      max-height: 529px *.7;
    }
  }
}

.info {
  padding-top: 124px;
  @media #{$before-md} {
    padding-top: 124px * .5;
  }
  > div {
    padding-left: 49px;
    text-align: left;

    @media #{$before-sm} {
      padding-left: 49px * .5;
    }

    p {
      @extend .light;
      @extend %l;
      padding-top: 10px;
      color: $dark;
      max-width: 543px;
    }

    a {
      @extend %l;
      border-radius: 100px;
      padding: 18px 55px;
      color: $blue;
      border: solid 1px $blue;
      text-decoration: none;
      @media #{$before-md} {
        padding: (18px * .7) (55px * .7);
      }
    }
  }
}

.action {
  margin-top: 43px;
  line-height: 55px;
}