@import "src/assets/styles/variables";
@import "src/assets/styles/style.scss";

.acceptCelWrapper {
  min-height: 598px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.acceptCel {
  @extend %container;
}

.section {
  width: 50%;
  position: relative;

  @media #{$before-md} {
    width: 100%;
    text-align: center;
  }


  img {
    position: absolute;
    left: 0;
    top: 59px;
  
    padding-left: 49px;
    max-height: 554px;

    @media #{$before-md} {
      top: 59px * .7;
      right: 0;
      margin: 0 auto;
      max-width: 100%;
      position: relative;
      max-height: 554px *.7;
    }
  }
}



.info {
  padding-top: 124px;
  @media #{$before-md} {
    padding-top: 124px * .5;
  }
  > div {
    padding-left: 49px;
    text-align: left;

    @media #{$before-sm} {
      padding-left: 49px * .5;
    }

    p {
      @extend .light;
      @extend %l;
      padding-top: 10px;
      color: $dark;
      max-width: 543px;
    }

    a {
      @extend %l;
      border-radius: 100px;
      padding: 18px 55px;
      color: $blue;
      border: solid 1px $blue;
      text-decoration: none;
      @media #{$before-md} {
        padding: (18px * .7) (55px * .7);
      }
    }
  }
}

.action {
  margin-top: 43px;
  line-height: 55px;
}

//   .section-content {
//     padding-left: 89px;
//     .title {
//       width: 508px;
//     }
//     p {
//       padding-top: 10px;
//       color: $dark;
//       @extend .light;
//       font-size: 24px;
//       line-height: 34px;
//       max-width: 543px;
//     }

//     .action {
//       margin-top: 43px;
//       line-height: 55px;

//       a {
//         border-radius: 100px;
//         padding: 18px 55px;
//         color: #004ad1;
//         border: solid 1px #004ad1;
//         text-decoration: none;
//       }
//     }
//   }
// }
