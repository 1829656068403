
@import "src/assets/styles/variables";
@import "src/assets/styles/style.scss";

.simulator {
  @extend %container;
  background: #fff;
  padding-top: 67px;
  padding-bottom: 176px;
}

.simulatorContainer{
  width: 100%;
  display:block;
  margin: 0 auto;
}
.simulatorSubtitle {
  @extend .light;
  font-size: 22px;
  color: #666666;
  max-width: 477px;
  width: 100%;
  line-height: 27px;
}
.simulatorPays{
  width: 100%;
  display: inline-block;
  font-size: 0;
  @media #{$on-lg} {
    width: 100%;
    
  }
}
.simulatorData{
  margin: 0 auto;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  padding: 0 10px;
  @media #{$on-lg} {
    width: 50%;
    padding: 0 10%;
    box-sizing: border-box;
  }
}

.simulatorDataSelect{
  width:90%;
  height: 100%;
  max-width: 499px;
  max-height: 81px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
  border-radius: 6px;
  background-position: 92% center;
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-image: url('../../img/arrow.svg');
  margin: 13px auto;

  p{
    @extend .regular;
    font-size: 22px;
    color: #4E4E4E;
    float: left;
    padding: 5px 8px;
    -webkit-font-smoothing: antialiased;
  }
  select{
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    appearance: none;
    @extend .light;
    font-size: 22px;
    color: #4E4E4E;
    -webkit-font-smoothing: antialiased;
  }
  select:focus{
    border: none;
  }
}

.simulatorDataInput{
  width:90%;
  height: 100%;
  max-width: 499px;
  max-height: 81px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
  border-radius: 6px;
  margin: 13px auto;
  position: relative;
  p{
    @extend .regular;
    font-size: 22px;
    color: #4E4E4E;
    float: left;
    padding: 5px 8px;
    -webkit-font-smoothing: antialiased;
  }
  input{
    box-sizing: border-box;
    padding: 5px 19px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    appearance: none;
    display: block;  
    @extend .light;
    font-size: 22px;
    color: #4E4E4E;
    -webkit-font-smoothing: antialiased;
  }
  
}

.simulatorDataInput span { 
  position: absolute; 
  display: block; 
  left: 5px; 
  top: 40px; 
  z-index: 9; 
  @extend .light;
  font-size: 22px;
  color: #4E4E4E;
  -webkit-font-smoothing: antialiased;
}

.simulatorResult{
  margin: 0 auto;
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  @media #{$on-lg} {
    width: 50%;
  }
}
.simulatorResultContainer{
  height: 335px;
  width: 100%;
  max-width: 549px;
  background: #FFFFFF;
  box-shadow: 0 6px 16px 4px rgba(0,0,0,0.08);
  border-radius: 18.55px;
  box-sizing: border-box;
  margin: 0px auto;
}
.simulatorResultHeader{
  width: 100%;
  height: 100%;
  max-height: 269px;
  background-image: linear-gradient(-132deg, #003BA9 0%, #095EFA 100%);
  border-top-left-radius: 18.55px;
  border-top-right-radius: 18.55px;
  box-sizing: border-box;
  padding-left: 32px;
  h4{
    @extend .regular;
    padding-top:64px;
    font-size: 22px;
    color: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    line-height: 27px;
  }
  p{
    @extend .bold;
    font-size: 48px;
    color: #FFFFFF;
    line-height: 58px;
    -webkit-font-smoothing: antialiased;
    span{
      @extend .regular;
      font-size: 30px;
      color: #FFFFFF;
      line-height: 58px;
      -webkit-font-smoothing: antialiased;
    }
  }
  h5{
    @extend .light;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
  }
}
.simulatorResultFooter{
  @extend .light;
  width: 100%;
  height: 100%;
  max-height: 66px;
  
  p{
    text-align: center;
    font-size: 18px;
    color: #4E4E4E;
    padding: 22px 0;
    a{
      color: #004AD1;
      text-decoration: underline;
    }
  }
}
        
      
    
  

