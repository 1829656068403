@import "./reset";
@import "src/assets/styles/variables";

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: $font;
}

.root {
  position: relative;
}


