@import "src/assets/styles/variables";
@import "src/assets/styles/style.scss";

.paysWrapper {
  padding-top: 60px;
}

.pays {
  @extend %container;
  background: #fff;
  padding-top: 67px;
} 
.paysContainer{
  max-width: 1024px;
  width: 100%;
  display:block;
  margin: 0 auto;
}
.paysSubtitle {
  @extend .light;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
  max-width: 477px;
  width: 100%;
  @media #{$on-lg} {
    font-size: 22px;
    line-height: 27px;
  }
}
.paysReaderInfo{
  width: 100%;
  display: inline-block;
  font-size: 0;
  @media #{$on-lg} {
    width: 100%;
  }
}   
.paysReader{
  margin: 0 auto;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  @media #{$on-lg} {
    width: 50%;
  }
  img{
    width: auto;
  }
}
.paysReaderData{
  margin: 0 auto;
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  text-align: center;
  @media #{$on-lg} {
    width: 50%;
  }
}

.paysApp{
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  height: 270px;
  display: block;
  font-size: 0;
  box-sizing: border-box;
  @media #{$on-xl} {
    height: 90px;
  }
}

.paysAppItem{
  width: 100%;
  display: block;
  vertical-align: middle;
  box-sizing: border-box;
  height: 100%;
  max-height: 90px;
  @media #{$on-xl} {
    display: inline-block;
    width: 33.33%;
  }
}

.paysAppDownload{
  @extend .regular;
  box-sizing: border-box;
  height: 100%;
  max-height: 90px;
  padding: 33px 0;
  font-size: 18px;
  color: #004AD1;
  text-align: center;
  background-position: 5% center;
  background-repeat: no-repeat;
  background-size: 33px 44px;
  -webkit-font-smoothing: antialiased;
  @media #{$on-xl} {
    border-right: 1px solid #E6EBF2;
    background-image: url('../../img/calculator.png');
  }
}
.paysAppSell{
  @extend .regular;
  box-sizing: border-box;
  height: 100%;
  max-height: 90px;
  padding: 33px 0px 33px 40px;
  font-size: 18px;
  color: #004AD1;
  text-align: center;
  background-position: 12% center;
  background-repeat: no-repeat;
  background-size: 48px 45px;
  -webkit-font-smoothing: antialiased;
  @media #{$on-xl} {
    border-right: 1px solid #E6EBF2;
    background-image: url('../../img/map.png');
  }
}
.paysAppMovil{
  @extend .regular;
  box-sizing: border-box;
  height: 100%;
  max-height: 90px;
  padding: 33px 0;
  font-size: 18px;
  color: #004AD1 !important;
  text-align: center;
  background-position: 10% center;
  background-repeat: no-repeat;
  background-size: 31px 30px;
  -webkit-font-smoothing: antialiased;
  @media #{$on-xl} {
    background-image: url('../../img/Rectangleblue.svg');
    text-align: right;
  }
}
  
