@import "src/assets/styles/variables";

$blue-hero: #0656e7;
$hero-height: 816px;

.heroDevice {
  width: 100%;
  position: relative;
}

.heroDeviceWrapper {
  @extend %container;
  height: $hero-height;
  position: relative;
  background-color: $white;
}

.heroDeviceBackground {
  z-index: 0;
  position: absolute;
  right: -500px;
  top: 0;
  height: 100%;

  @media #{$on-md} {
    right: -200px;
  }

  @media #{$on-lg} {
    right: 0;
  }
}

.heroDeviceContent {
  position: relative;
  height: 100%;
}

.heroDeviceContentLeft {
  margin-left: 10%;
  width: 80%;
  float: left;

  @media #{$on-md} {
    margin-left: 5%;
    width: 41%;
  }

  div {
    margin-top: 359px;
  }
}

.heroDeviceContentRight {
  margin-left: 10%;
  width: 44%;
  float: left;
  display: none;

  @media #{$on-md} {
    display: block;
  }
}

.heroDeviceContentRightImage {
  width: 360px;
  margin-top: 320px;
  padding-top: 20px;

  @media #{$on-lg} {
    width: 520px;
    margin-top: 210px;
    padding-top: 20px;
  }
}
      
.heroDeviceCompleteBackground {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  height: $hero-height;
  width: 50%;
  background-color: $blue-hero;
  @media #{$on-sm} {
    display: block;
  }
}

