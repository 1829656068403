@import "src/assets/styles/variables.scss";

.solutionHomeCardWrapper {
  float: left;
  margin-bottom: 26px;
  width: 33%;

  @media #{$before-md} {
    width: 50%;
  }

  @media #{$before-sm} {
    width: 100%;
  }
}

.solutionHomeCard {
  box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.05);
  min-height: 168px;
  margin: 0 auto;
  width: 95%;
  @media #{$before-md} {
    min-height: 120px;
  }
}

.solutionHomeCardContent {
  float: left;
  width: 66%;
  margin-top: 45px;

  @media #{$before-md} {
    margin-top: 25px;
  }

  h2 {
    @extend .semibold;
    @extend %normal-title;
    color: $darklight;

  }

  p {
    @extend .light;
    @extend %normal;
    color: $darklight;
    padding-right: 10px;
    box-sizing: border-box;
  }

}

.solutionHomeCardIconWrapper {
  float: left;
  width: 33.3%;
  margin-top: 44px;

  @media #{$before-md} {
    margin-top: 22px;
  }
}

.solutionHomeCardIcon {
  margin: 0 auto;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: rgba(0, 74, 209, 0.07);
  text-align: center;
  position: relative;
  

  img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    float: left;
  }
}
