@import "src/assets/styles/style.scss";
@import "src/assets/styles/variables";

.questionsWrapper {
  margin-top: 103px;
}

.questions {
  @extend %container;
}

.questionSection {
  width: 100%;
  & > div {
    padding-left: 49px;

    @media #{$before-sm} {
      padding-left: 49px * 0.5;
    }
  }
  & > p {
    @extend %l;
    padding-top: 10px;
    color: $dark;
    max-width: 653px;
    padding-left: 49px;
    @media #{$before-sm} {
      padding-left: 49px * 0.5;
    }
  }
}

.questionsHomeCardContainer {
  padding-left: 0 !important;
  width: 100%;
  float: left;
  margin-top: 70px;
  margin-bottom: 100px;
  @media #{$before-md} {
    margin-top: 70px * .7;
    margin-bottom: 100px * .7;
  }
}
