@import "../style.scss";
@import "../variables.scss";

.commissionsDeviceWrapper {
  padding-top: 67px;
  background-color: #f6f7f9;
  min-height: 518px;
  margin-top: 50px;
  margin-bottom: 0px;
  padding-bottom: 70px;
}

.commissionsDevice {
  @extend %container;
}

.commissionsDeviceInstructions{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;  
}

.commissionsDeviceInstructions p{
  width: 100%;
  padding-top: 54px;
  box-sizing: border-box;
  @extend .regular;
  -webkit-font-smoothing: antialiased;
  line-height: 22px;
  font-size: 18px;
  color: #004AD1;
}
.commissionsDeviceAsterisk{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.commissionsDeviceAsterisk p{
  @extend .light;
  padding-top: 28px;
  padding-bottom: 67px;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
}


.commissionsDeviceCardContainer {
  width: 100%;
  margin-top: 68px;
  float: left;
  
  @media #{$on-lg} {
    margin-left: 26%;
  }
}

.commissionsDeviceCardWrapper{
  float: left;
  margin-bottom: 26px;
  width: 100%;
  box-sizing: border-box; 

  @media #{$on-lg} {
    width: 38.1%;
  }
}

.commissionsDeviceCard {
  box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.05);
  min-height: 168px;
  height: 100%;
  max-height: 275px;
  margin: 0 auto;
  width: 95%;
  box-sizing: border-box;
}

.cardHeader {
  height: 91px;
  background-color: #FFF;
  padding-top: 26px;
  display: block;
  text-align: center; 
  box-sizing: border-box;
}

.cardHeader img{
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
  
.commissionsDeviceCard hr{
  border-color: #EBF3FF;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  margin: 0;
}
.cardBody {
  height: 124px;
  background-color: #FFF;
  overflow-y: hidden;
  padding-top: 30px;
  text-align: center; 
  box-sizing: border-box;
}
.cardBody p {
  @extend .bold;
  color: #004AD1;
  font-size: 48px;
  overflow-y: hidden;
} 

.cardBody p span {
  @extend .bold;
  color:  #004AD1;
  font-size: 36px;
}

.cardFooter{
  height: 59px;
  background-color: #F1F7FF ;
  overflow-y: hidden;
  padding-top: 20px;
  text-align: center; 
  box-sizing: border-box;
}
.cardFooter p {
  @extend .regular;
  color: #004AD1;
  font-size: 18px;
  overflow-y: hidden;
}
.cardFooter p span {
  @extend .light;
  color: #004AD1;
  font-size: 18px;
}
  


