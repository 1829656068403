@import "src/assets/styles/variables";

.registerEcommerceCardWrapper {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  height: 280px;

  &:hover {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    border: 0 !important;
    background-color: #ffffff;
    -moz-box-shadow: 0 8px 20px 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 8px 20px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 20px 6px rgba(0, 0, 0, 0.2);
  }

  @media #{$on-xl} {
    width: 33%;
  }
}

.registerEcommerceCard {
  min-height: 168px;
  margin: 0 auto;
  width: 84%;
}

.registerEcommerceCardContent {
  float: left;
  width: 100%;
  margin-top: 8px;

  h2 {
    text-align: center;
    @extend .regular;
    @extend %l;
    color: $blue;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
    @extend .light;
    @extend %normal;
    color: $darklight;
  }
}

.registerEcommerceCardIconWrapper {
  text-align: center;
  margin: 0 auto;
}

.registerEcommerceCardIcon {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: rgba(0, 74, 209, 0.07);
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 14px;

  img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    float: left;
  }
}

.br {
  border-right: solid 1px #e6ebf2;
}

.bb {
  border-bottom: solid 1px #e6ebf2;
}

.bt {
  border-top: solid 1px #e6ebf2;
}
