@import "src/assets/styles/variables";

.questionsHomeCardWrapper {
  float: left;
  width: 33%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;

  @media #{$before-lg} {
    width: 50%;
  }

  @media #{$before-sm} {
    width: 100%;
  }
}

.questionsHomeCard {
  min-height: 168px;
  margin: 0 auto;
  width: 84%;
}

.questionsHomeCardContent {
  float: left;
  width: 100%;
  margin-top: 8px;

  h2 {
    @extend .regular;
    @extend %normal-title;
    color: $blue;
    margin-bottom: 8px;
  }

  p {
    @extend .light;
    @extend %normal;
    color: $darklight;
  }
}

.questionsHomeCardIconWrapper {
  float: left;
  width: 100%;
}

.questionsHomeCardIcon {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: rgba(0, 74, 209, 0.07);
  text-align: center;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    float: left;
  }
}