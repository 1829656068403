@import "src/assets/styles/variables";
@import "src/assets/styles/style.scss";

.commissionsDeviceCardWrapper{
  float: left;
  margin-bottom: 26px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  @media #{$on-lg} {
    width: 100%;
  }
}
.commissionsDeviceCardModal {
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0 8px 20px 6px rgba(11,49,149,0.08);
  border-radius: 10px;
  min-height: 168px;
  height: 100%;
  max-height: 467px;
  margin: 0 auto;
  width: 510%;
  max-width: 510px;
  box-sizing: border-box;
  .cardHeader {
    height: 135px; 
    background-color: #FFF;
    padding-top: 26px;
    display: block;
    text-align: center; 
    padding: 0 21px;
    box-sizing: border-box;
    .cardHeaderCost{
      padding-top: 28px;
      width: 100%;
      height: 100%;
      text-align: left;
      p{
        @extend .bold;
        font-size: 38px;
        color: #004AD1;
        @media #{$on-lg} {
          font-size: 48px;
        }
        
        span{
          @extend .regular;
          font-size: 26px;
          line-height: 46px;
          color: #004AD1;
          @media #{$on-lg} {
            font-size: 32px;
            line-height: 58px;
          }
        }
      }
      .cardHeaderSend{
        @extend .light;
        @extend %normal;
        color: #4E4E4E;
      } 
    }
  }
  hr{
    border-color: #EBF3FF;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    margin: 0;
  }
  .cardBody {
    height: 230px;
    background-color: #FFF;
    overflow-y: hidden;
    padding: 22px 25px 0px;
    box-sizing: border-box;
    .cardBodyItem{
      @extend .light;
      @extend %normal;
      color: #4E4E4E;
      margin-bottom: 10px;
      text-align: left;
      padding-left: 15px;
      background-image: url('../../img/Rectangle27.svg');
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
    .cardBodyMoreInfo{
      @extend .regular;
      @extend %normal;
      margin-top: 14px;
      text-decoration: underline;
      padding-left: 15px;
      text-align: left;
      color: #004AD1;
    }
  }
  .cardFooter{
    height: 145px;
    background-color: #F1F7FF ;
    overflow-y: hidden;
    padding-top: 20px;
    box-sizing: border-box;
    button {
      @extend %normal;
      @extend .regular;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      border: none;
      background: #004AD1;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.20);
      border-radius: 100px; 
      color: #FFFFFF;
      text-align: center;
      line-height: 22px;
      width: 261px;
      height: 59px;
      
    }
  }
}

.modal {
  width: 95%;
  max-width: 800px;
  width: 95%;
  max-width: 800px;
  background-color: #ff5959;
  /* left: 50%; */
  /* margin-left: -400px; */
  position: relative;
  z-index: 10;
  top: 10vh;
  padding: 20px 4% 10px;
  color: #fff;
  font-weight: 300;
  margin: 0 auto;
}
.modal:focus {outline:0;}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(167, 34, 34, 0.80);
}

.modalClose{
  position: absolute;
  top:15px;
  right: 15px;
}
.modalCloseImage{
  max-width: 30px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modalContainer{
  background-color: #ff5959;
}

.modalContainer h2{
  font-size: 1.9em;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.4em;
  color: #FFF;
}

.modalContainer p{
  font-size: 1em;
  line-height: 1.4em;
  color: #FFF;
}
.modalContainer ul {
  list-style: disc;
  padding-left: 20px;
}
.modalContainer ul > li{
  font-size: 1em;
  line-height: 1.4em;
  color: #FFF;
}