@import "src/assets/styles/variables.scss";

.solutionHomeWrapper {
  padding-top: 60px;
}

.solutionHome {
  @extend %container;
  > h2 {
    margin-left: 45px;
    max-width: 487px;

    @media #{$before-sm} {
      margin-left: 22px;
    }

    @media #{$before-xs} {
      margin-left: 0;
    }
  }
}

.solutionHomeCardContainer {
  margin-top: 68px;
  float: left;
}