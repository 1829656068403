@import url("/src/assets/styles/montserrat.scss");

$font: "Montserrat";
$font-size: 18px;
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;
$white: #ffffff;
$dark: #363636;
$darklight: #4e4e4e;
$blue: #004ad1;

$break-xs: 576px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;

$content-width: 1395px;

$on-xs: "(min-width : 0px)";
$on-sm: "(min-width : #{$break-xs})";
$on-md: "(min-width : #{$break-sm})";
$on-lg: "(min-width : #{$break-md})";
$on-xl: "(min-width : #{$break-lg})";

$before-xs: "(max-width : #{$break-xs - 1})";
$before-sm: "(max-width : #{$break-sm - 1})";
$before-md: "(max-width : #{$break-md - 1})";
$before-lg: "(max-width : #{$break-lg - 1})";

%container {
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 20px;
}

%small {
  font-size: 14px;
  @media #{$before-md} {
    font-size: 12px;
  }
}

$normal-font: 18px;
$normal-line: 20px;
%normal {
  -webkit-font-smoothing: antialiased;
  font-size: $normal-font;
  line-height: $normal-line;
  @media #{$before-md} {
    font-size: $normal-font * .8;
    line-height: $normal-line * .8;
  }
}

$normal-title-font: 20px;
$normal-title-line: 24px;
%normal-title {
  -webkit-font-smoothing: antialiased;
  font-size: $normal-title-font;
  line-height: $normal-title-line;
  @media #{$before-md} {
    font-size: $normal-title-font * .8;
    line-height: $normal-title-line * .8;
  }
}

$l-font: 24px;
$l-line: 29px;

%l {
  -webkit-font-smoothing: antialiased;
  font-size: $l-font;
  line-height: $l-line;
  @media #{$before-md} {
    font-size: $l-font * .8;
    line-height: $l-line * .8;
  }

  @media #{$before-sm} {
    font-size: $l-font * .75;
    line-height: $l-line * .75;
  }

  @media #{$before-xs} {
    font-size: $l-font * .70;
    line-height: $l-line * .70;
  }
}

$xl-font: 38px;
$xl-line: 47px;
%xl {
  -webkit-font-smoothing: antialiased;
  font-size: $xl-font;
  line-height: $xl-line;
  @media #{$before-md} {
    font-size: $xl-font * .8;
    line-height: $xl-line * .8;
  }

  @media #{$before-sm} {
    font-size: $xl-font * .6;
    line-height: $xl-line * .6;
  }

}

%xxl {
  -webkit-font-smoothing: antialiased;
  font-size: 45px;
  line-height: 55px;
  @media #{$before-md} {
    font-size: 45px * .8;
    line-height: 55px * .8;
  }

  @media #{$before-sm} {
    font-size: 45px * .7;
    line-height: 55px * .7;
  }

  @media #{$before-xs} {
    font-size: 45px * .5;
    line-height: 55px * .5;
  }
}

.light {
  font-weight: $font-light;
}

.regular {
  font-weight: $font-regular;
}

.semibold {
  font-weight: $font-semibold;
}

.bold {
  font-weight: $font-bold;
}

.white {
  color: $white;
}

.container {
  @extend %container;
}

.left {
  float: left;
}

.right {
  float: right;
}

.xsHide {
  @media #{$before-xs} {
    display: none !important;
  }
}

.smHide {
  @media #{$before-sm} {
    display: none !important;
  }
}

.mdHide {
  @media #{$before-md} {
    display: none !important;
  }
}

.lgHide {
  @media #{$before-lg} {
    display: none !important;
  }
}

.xsShow {
  @media #{$before-xs} {
    display: block !important;
  }
}