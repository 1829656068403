@import "src/assets/styles/variables";

.heroTitle {
  @extend .white;
  @extend .semibold;
  @extend %xxl;
}

.heroSubtitle {
  @extend .white;
  @extend .light;
  @extend %l;

  @media #{$before-xs} {
    display: inline-block;
    padding: 0 40px 0 40px;
    word-wrap:break-word;
  }
}

.heroButtons {
  margin-top: 37px;
  position: relative;
  @media #{$before-sm} {
    text-align: center;
  }
}

.heroButton {
  @extend .regular;
  @extend %normal;
  display: inline-block;
  padding: 5px 0px;
  color: $white;
  text-decoration: none;
  margin-right: 20px;

  @media #{$before-md} {
    padding: 7px 20px;
    margin-bottom: 10px;
  }

  @media #{$before-sm} {
    padding: 0;
    margin: 0 auto;
    width: max-content;
    margin-bottom: 20px;
    display: block;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    -o-transition: transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    @media #{$before-sm} {
      height: 30px;
      width: 30px;
    }
  }
}

.heroButtonGreen {
  padding: 13px 56px;
  border-radius: 100px;
  background-color: #51ce68;
  color: $white;
}
