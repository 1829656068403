@import "src/assets/styles/variables";

.text {
  font-weight: $font-semibold;
  @extend %xl;
}
.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.hrCenter {
  height: 0;
  background-color: $blue;
  border: solid 3.5px $blue;
  width: 49px;
  margin: 0 auto;
}

.hrLeft {
  height: 0;
  background-color: $blue;
  border: solid 3.5px $blue;
  width: 49px;
  margin-left: 5px;
}
