@import "src/assets/styles/variables";

.heroFooter {
  float: left;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
  height: 50px;
  text-align: right;

  @media #{$before-sm} {
    position: absolute;
    text-align: center;
    padding-top: 0;
    padding-bottom: 15px;
    bottom: -90px;
  }
}

.heroFooterCenter {
  float: left;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
  height: 50px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 15px;
}

.heroFooterBrand {
  height: 32px;
  margin: 8px 10px 8px 10px;

  @media #{$before-md} {
    margin: 8px 8px 8px 8px;
  }
}
