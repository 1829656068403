// $fa-font-path: "font-awesome/fonts";
// @import "~font-awesome/scss/font-awesome";
@import "src/assets/styles/variables.scss";

.headerWrapper {
  left: 0;
  margin-top: 63px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  @media #{$before-md} {
    margin-top: 43px;
  }
}

.header {
  @extend .container;
  box-sizing: inherit;
}

.brand {
  float: left;
  margin-left: 2%;
  height: 40px;
  img {
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }
}

.collapse {
  float: right;
}

.collapseButton {
  @extend %small;
  @extend .white;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  border-radius: 100%;
  color: $blue;
  border: solid 1px $blue;
  background: none;
  @media #{$before-sm} {
    border: solid 1px $white;
    color: $white;
    background: transparent;
  }
  @media #{$on-lg} {
    display: none;
  }
  padding: 0 13px 0 13px !important;
}

.navigation {
  float: right;
  @media #{$before-md} {
    display: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @extend %small;
      @extend .white;
      display: inline;
      margin: 0 4px 0 4px;
      a {
        @extend %small;
        @extend .white;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 8px 0 8px;
        text-decoration: none;
      }
    }
  }
}

.navigationMobile {
  width: 100%;
  float: left;
  display: block !important;
  padding: 0 20px 0px 20px;
  color: $dark;
  box-sizing: border-box;
  background: $white;
  box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.05);
  li {
    margin: 0 auto;
    a {
      @extend %normal;
      text-align: center;
      padding: 0 !important;
      color: $blue !important;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      border-bottom: solid 1px lighten($blue, 50%);
      background-color: transparent !important;
      border-radius: 0 !important;
    }
  }
}

.buttonLast {
  border: none !important;
}

.inverse {
  color: $blue !important;
}

.button {
  border-radius: 91px;
  color: $blue !important;
  background: $white;
  padding: 0 28px 0 28px !important;
}

.buttonInverse {
  color: $white !important;
  background: $blue;
}
