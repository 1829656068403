@import "src/assets/styles/variables";
@import "src/assets/styles/style.scss";

.transactionsDeviceWrapper {
  background-color: #f6f7f9;
  width: 100%;
  padding-bottom: 70px;
}
.transactionsDevice {
  @extend %container;
  width: 100%;
}
.transactionsContainer {
  width: 100%;
  margin: 0 auto;
  background-color: #f6f7f9;
  display: block;
  font-size: 0;
  box-sizing: border-box;
}
.transactionsDeviceImage {
  display: block;
  width: 95%;
  margin: 0 auto;
  padding: 0 4px;
  box-sizing: border-box;
  @media #{$on-lg} {
    display: inline-block;
    width: 35%;
  }
  img {
    height: 100%;
    position: relative;
    top: -80px;
    max-height: 379px;
    width: 100%;
    max-width: 522px;
  }
}
.transactionsDeviceInfo {
  width: 95%;
  padding-top: 40px;
  display: block;
  box-sizing: border-box;
  padding-left: 15px;
  @media #{$on-lg} {
    display: inline-block;
    width: 65%;
    padding-left: 81px;
  }
  h2 {
    max-width: 508px;
  }
  .transactionsSubtitle {
    @extend .light;
    font-size: 22px;
    padding-top: 7px;
    color: #363636;
    max-width: 709px;
  }
  img {
    padding-top: 36px;
    max-width: 198px;
  }
}
