@import "src/assets/styles/variables";

.registerWrapper {
  position: relative;
}

.register {
  @extend %container;
}

.content {
  margin-top: 50px;
}

.header {
  p {
    margin-top: 16px;
    @extend .light;
    @extend %normal-title;
  }
}

.gridSection {
  float: left;
  width: 72%;
  @media #{$before-lg} {
    width: 100%;
  }
}

.cardSection {
  float: left;
  width: 25%;
  margin-left: 3%;
  @media #{$before-lg} {
    float: none;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
  img {
    margin-top: 90px;
    width: 100%;
    max-width: 382px;
    @media #{$before-lg} {
      width: auto;
      height: 160px;
      margin: 0 auto;
    }
  }
  p {
    max-width: 310px;
    margin-top: 39px;
    text-align: center;
    @extend %l;
    @extend .light;
    @media #{$before-lg} {
      max-width: 100%;
    }
  }
}