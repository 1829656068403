@import "src/assets/styles/variables";
// $fa-font-path: "font-awesome/fonts";
// @import "~font-awesome/scss/font-awesome";

$blue-hero: #095dfa;
$hero-height: 882px;

.heroEcommerceWrapper {
  width: 100%;
  position: relative;
}

.heroEcommerce {
  @extend %container;
  max-height: $hero-height;
  position: relative;
  box-sizing: border-box;
  background: transparent;
}

.heroEcommerceBackground {
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80%;
}

.heroEcommerceContent {
  position: relative;
  height: 100%;
}

.heroEcommerceContentLeft {
  @extend .left;
  width: 100%;
  text-align: center;

  & > div {
    margin-top: 161px;
    padding-left: 30px;

    @media #{$before-md} {
      margin-top: 161px * 0.8;
    }
  }
}

.heroEcommerceContentRight {
  @extend .left;
  width: 100%;
}

.heroEcommerceContentImage {
  height: 337px;
  margin-top: 67px;
  height: auto;
  text-align: center;
  position: relative;

  @media #{$before-md} {
    height: 337px * 0.8;
  }
  @media #{$before-sm} {
    height: 337px * 0.5;
  }

  img {
    height: 100%;
    left: 0;
    max-width: 100%;
    position: relative;
  }
}

.modalClose {
  color: $white;
  position: absolute;
  z-index: 99999;
  right: 0;
  top: -20px;
}

.heroEcommerceModalContainer {
  background: #00000080;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
}
.heroEcommerceModal {
  margin: 20% auto 0;
  max-width: 500px;
  text-align: center;
  background: #FFF;
  border-radius: 10px;
  padding: 40px;
  position: relative;
}
.heroEcommerceModal .heroEcommerceModalClose {
  cursor: pointer;
  background: #fff;
  border: 3px solid #333;
  text-align: center;
  height: 38px;
  width: 38px;
  border-radius: 25px;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  right: 10px;
  top: 10px;
}
.heroEcommerceModal .modal-video-movie-wrap {
  padding-bottom: 0!important;
}
