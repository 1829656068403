.first {
  z-index: 999;
}

.first.active .item {
  opacity: 1;
}

.second {
  z-index: 98;
}
.second.active .item {
  opacity: 1;
  margin-top: 14px;
  margin-left: -130px;
  height: 420px !important;
}

.third {
  z-index: 97;
}
.third.active .item {
  opacity: 1;
  margin-top: 25px;
  margin-left: -265px;
  height: 390px !important;
  margin-right: 100px;
}

@media (max-width: 991px) {
  .second.active .item {
    margin-left: -110px;
    height: 336px !important;
  }
  .third.active .item {
    margin-top: 25px;
    margin-left: -225px;
    height: 312px !important;
  }
}

@media (max-width: 776px) {
  .first.active .item {
    width: 340px !important;
    height: 360px !important;
  }

  .second.active .item {
    width: 340px !important;
    height: 324px !important;
  }

  .third.active .item {
    width: 340px !important;
    height: 288px !important;
  }
}
