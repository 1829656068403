@import "src/assets/styles/style.scss";
@import "src/assets/styles/variables";

.simulatorData{
  margin: 0 auto;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  padding: 6% 10%;
  @media #{$on-lg} {
    width: 100%;
    padding: 13% 10%;
    box-sizing: border-box;
  }
}

.simulatorDataSelect{
  width:100%;
  height: 100%;
  max-width: 499px;
  max-height: 81px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
  border-radius: 6px;
  background-position: 92% center;
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-image: url('./../../../assets/img/arrow.svg');
  margin: 13px 0px;

  p{
    @extend .regular;
    font-size: 18px;
    color: #4E4E4E;
    float: left;
    padding: 5px 8px;
    -webkit-font-smoothing: antialiased;
    @media #{$on-lg} {
      font-size: 22px;
    }
  }
  select{
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    appearance: none;
    @extend .light;
    font-size: 18px;
    color: #4E4E4E;
    -webkit-font-smoothing: antialiased;
    @media #{$on-lg} {
      font-size: 22px;
    }
  }
  select:focus{
    border: none;
  }
}